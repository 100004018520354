/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url("https://fonts.gstatic.com/s/lato/v11/dPJ5r9gl3kK6ijoeP1IRsvY6323mHUZFJMgTvxaG2iE.woff2") format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: local('Lato Light'), local('Lato-Light'), url("https://fonts.gstatic.com/s/lato/v11/EsvMC5un3kjyUhB9ZEPPwg.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url("https://fonts.gstatic.com/s/lato/v11/UyBMtLsHKBKXelqf4x7VRQ.woff2") format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: local('Lato Regular'), local('Lato-Regular'), url("https://fonts.gstatic.com/s/lato/v11/1YwB1sO8YE1Lyjf12WNiUA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url("https://fonts.gstatic.com/s/lato/v11/ObQr5XYcoH0WBoUxiaYK3_Y6323mHUZFJMgTvxaG2iE.woff2") format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: local('Lato Bold'), local('Lato-Bold'), url("https://fonts.gstatic.com/s/lato/v11/H2DMvhDLycM56KNuAtbJYA.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'), url("https://fonts.gstatic.com/s/oswald/v11/l1cOQ90roY9yC7voEhngDBJtnKITppOI_IvcXXDNrsc.woff2") format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 300;
  src: local('Oswald Light'), local('Oswald-Light'), url("https://fonts.gstatic.com/s/oswald/v11/HqHm7BVC_nzzTui2lzQTDVtXRa8TVwTICgirnJhmVJw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url("https://fonts.gstatic.com/s/oswald/v11/yg0glPPxXUISnKUejCX4qfesZW2xOQ-xsNqO47m55DA.woff2") format('woff2');
  unicode-range: U+0100-024F, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  src: local('Oswald Regular'), local('Oswald-Regular'), url("https://fonts.gstatic.com/s/oswald/v11/pEobIV_lL25TKBpqVI_a2w.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* latin */
@font-face {
  font-family: 'Satisfy';
  font-style: normal;
  font-weight: 400;
  src: local('Satisfy'), url("https://fonts.gstatic.com/s/satisfy/v6/2OzALGYfHwQjkPYWELy-cw.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}
/* General Styles */
.row {
  width: 90%;
  max-width: 1350px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
}
.row.expanded {
  max-width: 100%;
  width: 100%;
}
.button {
  display: inline-block;
  cursor: pointer;
  -webkit-appearance: none;
  transition: background-color 0.25s ease-out,color 0.25s ease-out;
  border-radius: 0;
  padding: 0.85em 2em;
  margin: 0;
  font-size: 0.8rem;
  letter-spacing: 1px;
  background-color: #ffba00;
  color: #fefefe;
  text-transform: uppercase;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  transition: 0.4s;
  &:hover {
    background-color: darken($brand, 6);
  }
}
body {
  font-family: lato;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: oswald;
  text-transform: uppercase;
  letter-spacing: 5px;
  font-weight: lighter;
}
/* - Top Bar Styles - */
#header {
  background-color: #fff;
}
#header .sticky {
  background-color: #fff;
}
.sticky {
  transform: none;
  z-index: 3;
}
#header .is-stuck {
  box-shadow: 0 0 10px rgba(0,0,0,.2);
}
.header-left.column {
  padding: 0.8rem 0;
}
.menu#right-menu {
  float: right;
  width: auto;
}
.header-right li a {
  color: #3f3f3f;
  font-size: 0.9em;
  text-transform: uppercase;
}
#right-menu li a:hover {
  color: $brand;
}
.menu-icon::after {
  background: #000;
  box-shadow: 0 7px 0 #000, 0 14px 0 #000;
}
.logo {
  float: left;
}
.menu-button {
  position: absolute;
  right: 2%;
  top: 50%;
  transform: translateY(-50%);
}
.button.toggle {
  margin: 0;
}
.panel-relative {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
}
.simple-panel {
  position: fixed;
  height: 100%;
  width: 400px;
  right: 0;
  top: 0;
  background: $brand;
  list-style-type: none;
  padding: 1em;
  z-index: 9999;
  transform: translateX(100%);
  -webkit-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
  -moz-transition: transform cubic-bezier(1, 0.21, 0, 1.07) 0.45s;
  transition: transform cubic-bezier(1, 0.21, 0.26, 0.98) 0.45s;
  box-shadow: none;
}
.simple-panel.is-active {
  box-shadow: 0.01em 0 0.5em #737373;
  transform: translateX(0);
}
.simple-panel li {
  color: #fff;
  display: block;
  padding: 1rem 1.5rem;
}
.simple-panel a {
  color: #fff;
  display: block;
  padding: 1rem 1.5rem;
}
.panel-menu {
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
}
/* - Scroll to top - */
.scrollup {
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  display: none;
  /* text-indent: -9999px; */
  background-color: #FFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  color: #0A0A0A;
  line-height: 40px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
/* - Slideshow Styles - */
#slideshow {
  position: relative;
  max-height: 39vw;
  overflow: hidden;
}
.slide {
  background-color: #000;
  img {
    opacity: 0.9;
  }
}
.owl-item {
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0) scale(1.0, 1.0);
}
.slideshow-text {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  right: 0;
  margin: 0 auto;
  text-align: center;
  z-index: 2;
  h2 {
    color: #fff;
    font-size: 1.2em;
  }
  h1 {
    color: #fff;
    font-family: satisfy;
    text-transform: none;
    letter-spacing: 0;
    margin-top: 0.5em;
    margin-bottom: 1em;
  }
  a {
    padding: 1.2em 3em;
  }
}
/* Page Styles */
section#content {
  padding: 5% 0;
  .row .columns {
    padding: 0 3em;
    /*a {
      display: inline-block;
      cursor: pointer;
      -webkit-appearance: none;
      transition: background-color 0.25s ease-out,color 0.25s ease-out;
      border-radius: 0;
      padding: 0.85em 2em;
      margin: 0;
      font-size: 0.8rem;
      letter-spacing: 1px;
      background-color: #ffba00;
      color: #fefefe;
      text-transform: uppercase;
      -webkit-transition: 0.4s;
      -moz-transition: 0.4s;
      transition: 0.4s;
      &:hover {
        background-color: darken($brand, 6);
      }
    }*/
  }
}
section.top-home-content {
  background-image: url("../../images/tree-bg-right.png"), url("../../images/tree-bg-left.png");
  background-position: right top, left bottom;
  background-repeat: no-repeat;
  background-size: 29vw;
  padding-bottom: 10em;
}
.top-home-split {
  .row:first-of-type {
    background-color: #fff;
    a {
      display: inline-block;
      cursor: pointer;
      -webkit-appearance: none;
      transition: background-color 0.25s ease-out,color 0.25s ease-out;
      border-radius: 0;
      padding: 0.85em 2em;
      margin: 0;
      font-size: 0.8rem;
      letter-spacing: 1px;
      background-color: #ffba00;
      color: #fefefe;
      text-transform: uppercase;
    }
  }
  .row:last-of-type {
    background-color: $brand;
    h3,
    p {
      color: #fff;
    }
    a {
      background-color: #383838;
      display: inline-block;
      cursor: pointer;
      -webkit-appearance: none;
      transition: background-color 0.25s ease-out,color 0.25s ease-out;
      border-radius: 0;
      padding: 0.85em 2em;
      margin: 0;
      font-size: 0.8rem;
      letter-spacing: 1px;
      color: #fefefe;
      text-transform: uppercase;
    }
  }
  .top-home-split-pad {
    padding: 2.5em;
  }
}
.top-home-split {
  margin-top: -3%;
  z-index: 2;
  position: relative;
}
.top-home-text {
  padding: 9% 0;
}
.things-to-do-wrap {
  background-color: $brand;
  color: #fff;
}
.things-to-do {
  padding: 4em 0;
  .large-3 {
    padding: 0 3em;
    border-left: solid 1px #fff;
    &:first-of-type {
      border: none;
    }
    .thing-to-do-icon {
      float: left;
      width: 25%;
      color: #e1a506;
      font-size: 35px;
    }
    .thing-to-do-content {
      float: right;
      width: 75%;
    }
    hr {
      border: none;
      background-color: #fff;
      display: block;
      width: 40%;
      height: 3px;
      margin: 2em 0;
    }
  }
}
.collage-section {
  background-image: url("../../images/collage-section-bg.png");
  background-size: cover;
  color: #fff;
  .collage-left-section {
    padding-left: 15%;
    padding-right: 3%;
  }
}
section#testimonials {
  background-image: url("../../images/testimonials-bg.png");
  background-position: center;
  padding: 8% 0;
  color: #fff;
  position: relative;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  p {
    color: #fff;
    font-weight: lighter;
    font-size: 23px;
  }
}
.home-contact-details {
  padding: 5%;
  .fa-map {
    float: left;
    height: 100px;
    padding-top: 6px;
    margin-right: 17px;
    color: $brand;
  }
}
.home-contact-bottom {
  i {
    margin-right: 17px;
    color: $brand;
    &:last-of-type {
      margin-left: 40px;
    }
  }
  * {
    display: inline-block;
  }
}
footer {
  background-color: #3a3a3a;
  padding: 2em 0;
  p {
    color: #bbbbbb;
    margin: 0;
    font-size: 0.8em;
    a {
      color: #fff;
      &:hover {
        color: $brand;
      }
    }
  }
}
#content.attractions {
  h4 {
    &::after {
      content: '';
      display: block;
      margin: 20px 0 25px;
      height: 4px;
      width: 50px;
      background-color: $brand;
    }
  }
}
.attractions-top-row {
  padding-bottom: 3em;
}
#map.contact-map {
  height: 24vw;
}
/* Styles for Testimonial Slider */
.testimonial-img-l,
.testimonial-img-r {
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.testimonial-img-l {
  left: 0;
}
.testimonial-img-r {
  right: 0;
}
.testimonial-slider {
  .owl-dots {
    text-align: center;
  }
  .owl-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    background-color: #fff;
    opacity: 0.8;
    border-radius: 50%;
    &:last-of-type {
      margin-right: 0;
    }
    &.active {
      background-color: $brand;
      opacity: 1;
    }
  }
}
/* Social Media Icons */
.social .fa {
  color: #fff;
}
.social .fa-circle {
  color: #000;
}
.facebook-icon .fa-circle {
  color: #3b5998;
}
.twitter-icon .fa-circle {
  color: #55ACEE;
}
.youtube-icon .fa-circle {
  color: #cd201f;
}
.linkedin-icon .fa-circle {
  color: #0077b5;
}
.social a:hover .fa-circle {
  color: $brand;
}
/* Contact Form Styles */
.contact-left {
  p {
    font-size: 1.4em;
    strong {
      font-size: 1.2em;
    }
  }
}
.contact-page-social {
  margin-top: 2em;
}
.input-group {
  display: block;
}
.mobile-menu-wrap {
  .close-button {
    padding: 0.5em;
    position: absolute;
    top: 1rem;
    right: 1rem;
    line-height: 21px;
    span {
      color: #fff;
      font-size: 2.5rem;
      font-weight: lighter;
    }
  }
}
#cookie-notice {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 300px;
    padding: 15px;
    font-size: 14px;
    background: #2d2d2d;
    color: #fff;
    z-index: 500;
}
.menu-btn-wrap{
    display: none;
}
/* ------------Max-width-media----------- */
@media screen and (max-width: 75em) {
  .top-home-split .top-home-split-pad {
    padding: 1.5em;
  }
}
@media screen and (max-width: 64em) {
    .menu-btn-wrap{
        display: block;
    }
    .mobile-menu-wrap{
        .menu.vertical{
            flex-wrap: wrap;
            li{
                flex: 0 0 100%;
            }
        }
        .close-button{
            display: block !important;
        }
    } 
}
@media screen and (max-width: 64em) {
  .things-to-do-img-wrap img {
    padding: 2em;
  }
  .thing-to-do:nth-of-type(3),
  .thing-to-do:nth-of-type(4) {
    margin-top: 3em;
  }
  .things-to-do .large-3 .thing-to-do-icon {
    display: none;
  }
  .things-to-do .large-3 .thing-to-do-content {
    width: 100%;
  }
  .collage-section .collage-left-section {
    padding: 0;
    margin: 4em 0;
  }
  .top-home-split .row:first-of-type {
    background-color: transparent;
  }
  .top-home-split .row:last-of-type {
    background-color: #ffba00;
    padding-top: 3em;
    padding-bottom: 2em;
    margin-top: 2em;
  }
  section.top-home-content {
    background-image: none;
  }
  .testimonial-img-l,
  .testimonial-img-r {
    display: none;
  }
  .mobile-menu-wrap {
    position: fixed;
    height: 100%;
    width: 400px;
    right: 0;
    top: 0;
    background: $brand;
    padding: 2em;
    z-index: 9999;
    transform: translateX(100%);
    -webkit-transition: transform cubic-bezier(1,.21,0,1.07) 0.45s;
    -moz-transition: transform cubic-bezier(1,.21,0,1.07) 0.45s;
    transition: transform cubic-bezier(1,.21,.26,.98) 0.45s;
    box-shadow: none;
    ul {
      margin-top: 3em;
    }
    ul li a {
      color: #fff;
      padding: 1em;
    }
    &.is-active {
      transform: translateX(0);
    }
  }
  .gallery-wrap {
    width: 85%;
    margin: 0 auto;
  }
  .gallery-main {
    padding: 0;
  }
  .gallery-wrap .owl-carousel.gallery-thumbs {
    display: none;
  }
  .gallery-main .owl-prev {
    top: auto;
    bottom: 0;
    left: 15px;
    font-size: 27px;
    height: 40px;
    width: 40px;
    line-height: 38px;
  }
  .gallery-main .owl-next {
    top: auto;
    bottom: 0;
    right: 15px;
    font-size: 27px;
    height: 40px;
    width: 40px;
    line-height: 38px;
  }
}
@media screen and (max-width: 700px) {
  .top-home-split {
    margin-top: 4em;
  }
}
@media screen and (max-width: 40em) {
  .mobile-menu-wrap {
    width: 100%;
  }
  #map.contact-map {
    height: 70vw;
  }
  .slideshow-text h1 {
    margin-bottom: 0;
  }
  .slide-scroll-btn {
    display: none;
  }
  section#testimonials {
    padding: 12% 6%;
    .testimonial p {
      font-size: 18px;
    }
  }
  .things-to-do-img-wrap img {
    margin-bottom: 5em;
    padding: 0 22%;
  }
  .simple-panel {
    width: 100%;
  }
}
/* ------------Min-width-media----------- */
@media screen and (min-width: 40em) {
  /* Fixes header on scroll for screens above mobile */
  .fixed {
    position: fixed;
    top: 0;
    z-index: 2;
    width: 100%;
  }
}

